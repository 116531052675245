<div class="modal-header">
  <h1>Try GAIMIN AI</h1>
  <h2>Get your API Access Key</h2>
  <span class="icon-Close close-icon" title="Close" (click)="closeModal()"></span>
</div>
<div class="modal-content">
  <form [formGroup]="form" class="form">
    <div class="form__field-group">
      <input class="form__field" id="name" formControlName="name" #name placeholder="First name" />
      <ng-container *ngIf="form.get('name')?.invalid && form.get('name')?.touched">
        <span *ngIf="form.get('name')?.hasError('required')" class="form__error">Name should be present</span>

        <span
          class="form__error"
          *ngIf="form.get('name')?.hasError('minlength') || form.get('name')?.hasError('maxlength'); else name_pattern">
          Name should have
          <mark>{{ nameMinLength }} - {{ nameMaxLength }}</mark>
          characters. You currently typed
          <mark>{{ (form.get('name')?.value).length }}</mark>
        </span>

        <ng-template #name_pattern>
          <span *ngIf="form.get('name')?.hasError('pattern')" class="form__error">
            Name should have only latin characters, hyphen and space. It must start and end with a Latin character.
          </span>
        </ng-template>
      </ng-container>
    </div>

    <div class="form__field-group">
      <input formControlName="email" class="form__field" type="text" placeholder="Email address" id="email" />

      <ng-container *ngIf="form.get('email')!.invalid && form.get('email')!.touched">
        <span *ngIf="form.get('email')!.hasError('required')" class="form__error"> Email should be present </span>
        <span *ngIf="form.get('email')!.hasError('email')" class="form__error"> Valid email address required </span>
      </ng-container>
    </div>

    <div class="form__field-group">
      <ngx-material-intl-tel-input fieldControlName="phone" [required]="true"> </ngx-material-intl-tel-input>
    </div>

    <div class="form__field-group">
      <input class="form__field" id="company" formControlName="company" #company placeholder="Company name" />
      <ng-container *ngIf="form.get('company')?.invalid && form.get('company')?.touched">
        <span
          class="form__error"
          *ngIf="
            form.get('company')?.hasError('minlength') || form.get('company')?.hasError('maxlength');
            else company_pattern
          ">
          Company name should have
          <mark>{{ nameMinLength }} - {{ nameMaxLength }}</mark>
          characters. You currently typed
          <mark>{{ (form.get('company')?.value).length }}</mark>
        </span>

        <ng-template #company_pattern>
          <span *ngIf="form.get('company')?.hasError('pattern')" class="form__error">
            Company name should have only latin characters, digits, hyphen and space.
          </span>
        </ng-template>
      </ng-container>
    </div>

    <div class="form__checkbox-group">
      <mat-checkbox formControlName="agreement" color="primary">
        I accept the <a routerLink="/eula" target="_blank">End User License Agreement</a>
      </mat-checkbox>
      <mat-checkbox formControlName="policy" color="primary">
        I accept the <a routerLink="/policy" target="_blank">Privacy Policy</a>
      </mat-checkbox>
    </div>

    <button class="form-button" (click)="onSubmit()" [disabled]="form.invalid">Get API Access Key</button>
  </form>
</div>
