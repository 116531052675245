import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SignupModalComponent } from '../../components/modals/signup-modal/signup-modal.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  constructor(private dialogService: MatDialog) {}

  openSignupModal() {
    this.dialogService.open(SignupModalComponent, {
      panelClass: 'dialog-overlay-pane'
    });
  }
}
