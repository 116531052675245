<div class="header">
  <a href="/"><img class="header__logo" src="assets/images/logo.svg" alt="GAIMIN AI" /></a>
  <div class="header__items">
    <a href="https://apis.gaimin.ai/GAIMIN-AI-11e16e4422d380cd8524c297758b9014" target="_blank" class="link"
      >Documentation</a
    >
    <a href="https://api.cloud.gaimin.io/ai/redoc" target="_blank" class="link">Our APIs</a>
    <a href="https://apis.gaimin.ai/GAIMIN-AI-11e16e4422d380cd8524c297758b9014" target="_blank"
      ><span class="icon-Info"></span
    ></a>
    <a href="https://api.cloud.gaimin.io/ai/redoc" target="_blank"><span class="icon-Documentation"></span></a>
    <button class="button" (click)="openSignupModal()">Start Free Trial</button>
  </div>
</div>
