<div class="footer">
  <div class="container">
    <div class="footer__logo">GAIMIN</div>
    <div class="footer__description">A next-generation infrastructure project set to revolutionize the industry.</div>

    <mat-accordion displayMode="flat">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>GAIMIN</mat-panel-title>
        </mat-expansion-panel-header>
        <ul>
          <li><a href="https://www.gaimin.io/about" target="_blank">About Us</a></li>
          <li><a href="https://www.gaimin.io/roadmap" target="_blank">Roadmap</a></li>
          <li><a href="https://www.gaimin.io/platform" target="_blank">Platform</a></li>
          <li><a href="https://www.gaimin.io/blog" target="_blank">Blog</a></li>
          <li><a href="https://www.gaimin.io/contact-us" target="_blank">Contact Us</a></li>
          <li class="support" (click)="openSupportPopup()">Support Center</li>
        </ul>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>Products</mat-panel-title>
        </mat-expansion-panel-header>
        <ul>
          <li>
            <a href="https://developer.gaimin.gg/auth/login" target="_blank">Developer Portal </a>
            <span class="top">Top</span>
          </li>
          <li><a href="https://www.gaimin.gg/" target="_blank">GAIMIN Platform</a></li>
          <li><a href="https://colosseum.gaimingladiators.gg/" target="_blank">GAIMIN Gladiators</a></li>
          <li>GME PRO <span class="soon">Soon</span></li>
        </ul>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>Resources</mat-panel-title>
        </mat-expansion-panel-header>
        <ul>
          <li><a href="https://www.gaimin.io/developer" target="_blank">Developers</a></li>
          <li><a href="https://www.gaimin.io/token" target="_blank">Token</a></li>
          <li><a href="https://www.gaimin.io/documents" target="_blank">Documents</a></li>
          <li><a href="https://www.gaimin.cloud/" target="_blank">Cloud</a></li>
          <li><a href="https://www.gaimin.io/press" target="_blank">Press</a></li>
          <li><a href="https://www.gaimin.io/careers" target="_blank">Careers</a></li>
        </ul>
      </mat-expansion-panel>
      <mat-expansion-panel class="support">
        <mat-expansion-panel-header>
          <mat-panel-title>Support</mat-panel-title>
        </mat-expansion-panel-header>
        <ul>
          <li (click)="openSupportPopup()">Support Center</li>
        </ul>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>Community</mat-panel-title>
        </mat-expansion-panel-header>
        <ul>
          <li><a href="https://t.me/+XFTC523WeTI1MjA0" target="_blank">Telegram</a></li>
          <li><a href="https://www.facebook.com/Gaimin.io/" target="_blank">Facebook</a></li>
          <li><a href="https://www.instagram.com/gaimin_io/" target="_blank">Instagram</a></li>
          <li><a href="https://twitter.com/GaiminIo" target="_blank">Twitter (X)</a></li>
          <li><a href="https://www.youtube.com/channel/UCp9Y_FMB1p4Nqjct0Ro9f3g" target="_blank">YouTube</a></li>
          <li><a href="https://www.linkedin.com/company/gaimin/" target="_blank">LinkedIn</a></li>
          <li><a href="https://discord.com/invite/jemqJ9PkCJ" target="_blank">Discord</a></li>
          <li><a href="https://www.tiktok.com/@gaimin.gg?lang=en" target="_blank">TokTok</a></li>
        </ul>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div class="info">
    <div class="container">
      <div class="info__links">
        <p>Copyright © {{ currentYear }} GAIMIN. All rights reserved.</p>
        <div>
          <a routerLink="/eula" target="_blank">End User License Agreement</a> /
          <a routerLink="/policy" target="_blank">Privacy Policy</a>
        </div>
      </div>
    </div>
  </div>
</div>
