import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SignupModalComponent } from '../../shared/components/modals/signup-modal/signup-modal.component';

@Component({
  selector: 'home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  selectedTabIndex = 1;
  tabs = [
    { label: 'Text to text', icon: 'icon-Text' },
    { label: 'Text to image', icon: 'icon-Image' },
    { label: 'Speech to text', icon: 'icon-Message' },
    { label: 'Text to speech', icon: 'icon-Message' },
    { label: 'Lip Sync', icon: 'icon-Video' }
  ];

  constructor(private dialogService: MatDialog) {}

  ngOnInit(): void {}

  openSignupModal() {
    this.dialogService.open(SignupModalComponent, {
      panelClass: 'dialog-overlay-pane'
    });
  }

  selectTab(index: number): void {
    this.selectedTabIndex = index;
  }
}
