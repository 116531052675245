import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SuccessModalComponent } from '../../../components/modals/success-modal/success-modal.component';
import { ActionsService } from '../../../services/actions.service';

@Component({
  selector: 'app-signup-modal',
  templateUrl: './signup-modal.component.html',
  styleUrls: ['./signup-modal.component.scss']
})
export class SignupModalComponent implements OnInit {
  nameMinLength: number = 2;
  nameMaxLength: number = 100;

  form: FormGroup = new FormGroup<any>({
    name: new FormControl('', [
      Validators.required,
      Validators.minLength(this.nameMinLength),
      Validators.maxLength(this.nameMaxLength),
      Validators.pattern('^[a-zA-Z]+(?:[ -][a-zA-Z]+)*[a-zA-Z]$')
    ]),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('', [Validators.required]),
    company: new FormControl('', [
      Validators.minLength(this.nameMinLength),
      Validators.maxLength(this.nameMaxLength),
      Validators.pattern('^[a-zA-Z0-9]+(?:[ -]?[a-zA-Z0-9]+)*$')
    ]),
    agreement: new FormControl(false, [Validators.requiredTrue]),
    policy: new FormControl(false, [Validators.requiredTrue])
  });

  constructor(
    private dialogRef: MatDialogRef<SignupModalComponent>,
    private actionsService: ActionsService,
    private dialogService: MatDialog
  ) {}

  ngOnInit(): void {}

  onSubmit() {
    if (!this.form.valid) {
      return;
    }

    const { name, email, phone, company } = this.form.value;
    this.actionsService.keyRequest({ name, email, phone, company }).subscribe((response) => {
      if (response.success) {
        this.closeModal();
        this.dialogService.open(SuccessModalComponent, {
          panelClass: ['dialog-overlay-pane', 'dialog-success']
        });
      }
    });
  }

  closeModal() {
    this.dialogRef.close();
  }
}
