import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActionsService } from '../../../services/actions.service';

@Component({
  selector: 'app-support-modal',
  templateUrl: './support-modal.component.html',
  styleUrls: ['./support-modal.component.scss']
})
export class SupportModalComponent implements OnInit {
  isFormSent: boolean = false;
  form: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    description: new FormControl('', [Validators.required])
  });

  constructor(
    private dialogRef: MatDialogRef<SupportModalComponent>,
    private actionsService: ActionsService
  ) {}

  ngOnInit(): void {}

  onSubmit() {
    if (!this.form.valid) {
      return;
    }

    this.actionsService.supportRequest(this.form.value).subscribe((response) => {
      if (response.success) {
        this.isFormSent = true;
      }
    });
  }

  closeModal() {
    this.dialogRef.close();
  }
}
