<div class="container">
  <div class="terms">
    <div class="back-btn" routerLink="/">
      <span class="icon icon-Arrow-Left"></span>
    </div>
    <h1 class="title">End User Licence Agreement</h1>
    <div class="html-content">
      <h2>PLEASE READ CAREFULLY BEFORE ACCESSING OR DOWNLOADING ANY SOFTWARE FROM THIS WEBSITE:</h2>
      <p>
        This licence agreement (<strong>Licence</strong>) is a legal agreement between you (<strong>Licence</strong> or
        <strong>you</strong>) and <strong>GAIMIN LDA</strong> of Rua José Alfredo da Costa Azevedo, Lisbon, 2635 585,
        Portugal (<strong>Licensor</strong>, <strong>us</strong> or <strong>yoweu</strong>) for:
      </p>

      <ul>
        <li>
          The <strong>GAIMIN Games Development Platform</strong> computer software, the data supplied with the software,
          the code providing the functionality and the associated media (<strong>Software</strong>); and
        </li>
        <li>Printed materials and/or online/electronic documents (<strong>Documents</strong>).</li>
      </ul>

      <p>
        We licence use of the Software and Documents to you on the basis of this Licence. We do not sell the Software or
        Documents to you. We remain the owners of the Software and Documents at all times.
      </p>

      <p><strong>OPERATING SYSTEM REQUIREMENTS:</strong> THIS SOFTWARE REQUIRES A COMPUTER WITH AN INTERNET BROWSER.</p>

      <h2>IMPORTANT NOTICE TO ALL USERS:</h2>
      <ul>
        <li>
          BY CLICKING ON THE “ACCEPT” BUTTON BELOW YOU AGREE TO THE TERMS OF THIS LICENCE WHICH WILL BIND YOU AND YOUR
          EMPLOYEES. THE TERMS OF THIS LICENCE INCLUDE, IN PARTICULAR, LIMITATIONS ON LIABILITY IN CONDITION 4.1 AND
          CONDITION 5.
        </li>
        <li>
          IF YOU DO NOT AGREE TO THE TERMS OF THIS LICENCE, YOU MUST CLICK ON THE “REJECT” BUTTON BELOW AND YOU MAY NOT
          ACCESS THIS SOFTWARE OR DOCUMENTS.
        </li>
      </ul>

      <h2>You should print a copy of this Licence for future reference.</h2>
      <ol>
        <li>
          <strong>Grant and Scope of Licence </strong>
          <ol>
            <li>
              In consideration of you agreeing to abide by the terms of this Licence, we grant to you a non-exclusive,
              non-transferable licence to use the Software and the Documents on the terms of this Licence.
            </li>
          </ol>
        </li>
        <li>
          <strong>Restrictions</strong>
          <ol>
            <li>
              Except as expressly set out in this Licence or as permitted by any local law, you undertake:
              <ol>
                <li>
                  not to copy the Software or Documents except where such copying is incidental to normal use of the
                  Software;
                </li>
                <li>
                  not to rent, lease, sub-license, loan, translate, merge, adapt, vary or modify the Software or
                  Documents;
                </li>
                <li>
                  not to make alterations to, or modifications of, the whole or any part of the Software, nor permit the
                  Software or any part of it to be combined with, or become incorporated in, any other programs;
                </li>
                <li>
                  not to disassemble, decompile, reverse-engineer or create derivative works based on the whole or any
                  part of the Software nor attempt to do any such thing except to the extent that (by virtue of section
                  296A of the Copyright, Designs and Patents Act 1988) such actions cannot be prohibited because they
                  are essential for the purpose of achieving interoperability of the Software with another software
                  program, and provided that the information obtained by you during such activities:
                  <ol>
                    <li>
                      is used only for the purpose of achieving interoperability of the Software with another software
                      program; and
                    </li>
                    <li>
                      is not unnecessarily disclosed or communicated without our prior written consent to any third
                      party; and
                    </li>
                    <li>
                      is not used to create any software which is substantially similar to, or in competition with the
                      Software;
                    </li>
                  </ol>
                </li>
                <li>
                  to supervise and control use of the Software and ensure that the Software is used by your employees
                  and representatives in accordance with the terms of this Licence;
                </li>
                <li>
                  not to provide or otherwise make available the Software in whole or in part (including but not limited
                  to program listings, object and source program listings, object code and source code), in any form to
                  any person other than your employees and/or representative without prior written consent from us;
                </li>
                <li>to comply with all applicable technology control or export laws and regulations; and</li>
                <li>not use the Software via any communications network or by means of remote access.</li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <strong>Intellectual Property Rights </strong>
          <ol>
            <li>
              You acknowledge that all intellectual property rights in the Software and the Documents anywhere in the
              world belong to us, that rights in the Software are licensed (not sold) to you, and that you have no
              rights in, or to, the Software or the Documents other than the right to use them in accordance with the
              terms of this Licence.
            </li>
            <li>You acknowledge that you have no right to have access to the Software in source code form.</li>
          </ol>
        </li>
        <li>
          <strong>Limited Warranty </strong>
          <ol>
            <li>
              We warrant that the Software will, when properly used and on an operating system for which it was
              designed, perform substantially in accordance with the functions described in the Documents for a period
              of 90 days from the date of your first access to the Software (<strong>Warranty Period</strong>).
            </li>
            <li>
              The warranty does not apply:
              <ol>
                <li>
                  if the defect or fault in the Software results from you having altered or modified the Software; and
                </li>
                <li>
                  if the defect or fault in the Software results from you having used the Software in breach of the
                  terms of this Licence.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <strong>Intellectual Property Rights </strong>
          <ol>
            <li>
              You acknowledge that the Software has not been developed to meet your individual requirements, including
              any particular cybersecurity requirements you might be subject to under law or otherwise, and that it is
              therefore your responsibility to ensure that the facilities and functions of the Software as described in
              the Documents meet your requirements.
            </li>
            <li>
              We only supply the Software and Documents for internal use by your business, and you agree not to use the
              Software or Documents for any resale purposes.
            </li>
            <li>
              We shall not in any circumstances whatever be liable to you, whether in contract, tort (including
              negligence), breach of statutory duty, or otherwise, arising under or in connection with the Licence for:
              <ol>
                <li>loss of profits, sales, business, or revenue;</li>
                <li>business interruption;</li>
                <li>loss of anticipated savings;</li>
                <li>wasted expenditure;</li>
                <li>loss or corruption of data or information;</li>
                <li>
                  loss of business opportunity, goodwill or reputation; where any of the losses set out in condition
                  5.3.1 to condition 5.3.6 are direct or indirect; or
                </li>
                <li>any special, indirect or consequential loss, damage, charges or expenses.</li>
              </ol>
            </li>
            <li>
              Other than the losses set out in condition 5.3 (for which we are not liable), our maximum aggregate
              liability under or in connection with this Licence whether in contract, tort (including negligence) or
              otherwise, shall in all circumstances be limited to a sum equal to EURO 1,000 (One thousand Euros). This
              maximum cap does not apply to condition 5.5.
            </li>
            <li>
              Nothing in this Licence shall limit or exclude our liability for:
              <ol>
                <li>death or personal injury resulting from our negligence;</li>
                <li>fraud or fraudulent misrepresentation;</li>
                <li>any other liability that cannot be excluded or limited by Portuguese law.</li>
              </ol>
            </li>
            <li>
              This Licence sets out the full extent of our obligations and liabilities in respect of the supply of the
              Software and Documents. Except as expressly stated in this Licence, there are no conditions, warranties,
              representations or other terms, express or implied, that are binding on us. Any condition, warranty,
              representation or other term concerning the supply of the Software and Documents which might otherwise be
              implied into, or incorporated in, this Licence whether by statute, common law or otherwise, is excluded to
              the fullest extent permitted by law.
            </li>
          </ol>
        </li>
        <li>
          <strong>Termination </strong>
          <ol>
            <li>
              We may terminate this Licence immediately by written notice to you if you commit a material or persistent
              breach of this Licence which you fail to remedy (if remediable) within 14 days after the service of
              written notice requiring you to do so.
            </li>
            <li>
              On termination for any reason:
              <ol>
                <li>all rights granted to you under this Licence shall cease;</li>
                <li>you must immediately cease all activities authorised by this Licence; and</li>
                <li>
                  you must immediately and permanently delete or remove the Software from all computer equipment in your
                  possession, and immediately destroy or return to us (at our option) all copies of the Software and
                  Documents in your possession, custody or control and, in the case of destruction, certify to us that
                  you have done so.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <strong>Communications Between Us </strong>
          <ol>
            <li>
              We may update the terms of this Licence at any time on notice to you in accordance with this condition 7.
              Your continued use of the Software and Documents following the deemed receipt and service of the notice
              under condition 7.3 shall constitute your acceptance to the terms of this Licence, as varied. If you do
              not wish to accept the terms of the Licence (as varied) you must immediately stop using and accessing the
              Software and Documents on the deemed receipt and service of the notice.
            </li>
            <li>
              If we have to contact you, we will do so by email or by pre-paid post to the address you provided in
              accordance with your registration for the Software.
            </li>
            <li>
              Note that any notice:
              <ol>
                <li>
                  given by us to you will be deemed received and properly served 24 hours after it is first posted on
                  our website, 24 hours after an email is sent, or three days after the date of posting of any letter;
                  and
                </li>
                <li>
                  given by you to us will be deemed received and properly served 24 hours after an email is sent, or
                  three days after the date of posting of any letter.
                </li>
              </ol>
            </li>
            <li>
              In proving the service of any notice, it will be sufficient to prove, in the case of posting on our
              website, that the website was generally accessible to the public for a period of 24 hours after the first
              posting of the notice; in the case of a letter, that such letter was properly addressed, stamped and
              placed in the post to the address of the recipient given for these purposes; and, in the case of an email,
              that such email was sent to the email address of the recipient given for these purposes.
            </li>
          </ol>
        </li>
        <li>
          <strong>Events Outside Our Control </strong>
          <ol>
            <li>
              We will not be liable or responsible for any failure to perform, or delay in performance of, any of our
              obligations under this Licence that is caused by an Event Outside Our Control. An Event Outside Our
              Control is defined below in condition 8.2.
            </li>
            <li>
              An <strong>Event Outside Our Control</strong> means any act or event beyond our reasonable control,
              including without limitation failure of public or private telecommunications networks.
            </li>
            <li>
              If an Event Outside Our Control takes place that affects the performance of our obligations under this
              Licence:
              <ol>
                <li>
                  our obligations under this Licence will be suspended and the time for performance of our obligations
                  will be extended for the duration of the Event Outside Our Control; and
                </li>
                <li>
                  we will use our reasonable endeavours to find a solution by which our obligations under this Licence
                  may be performed despite the Event Outside Our Control.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <strong>How We May Use Your Personal Information </strong>
          <p>
            Under data protection legislation, we are required to provide you with certain information about who we are,
            how we process the personal data of those individuals who use the Software and the Documents and for what
            purposes and those individuals’ rights in relation to their personal data and how to exercise them. This
            information is provided in our Privacy Policy and it is important that you read that information.
          </p>
        </li>
        <li>
          <strong>Other Important Terms </strong>
          <ol>
            <li>
              We may transfer our rights and obligations under this Licence to another organisation, but this will not
              affect your rights or our obligations under this Licence.
            </li>
            <li>
              You may only transfer your rights or your obligations under this Licence to another person if we agree in
              writing.
            </li>
            <li>
              This Licence constitutes the entire agreement between us and supersedes and extinguishes all previous and
              contemporaneous agreements, promises, assurances and understandings between us, whether written or oral,
              relating to its subject matter.
            </li>
            <li>
              You acknowledge that in entering into this Licence you do not rely on and shall have no remedies in
              respect of any statement, representation, assurance or warranty (whether made innocently or negligently)
              that is not set out in this Licence.
            </li>
            <li>
              You agree that you shall have no claim for innocent or negligent misrepresentation or negligent
              misstatement based on any statement in this Licence.
            </li>
            <li>
              A waiver of any right or remedy is only effective if given in writing and shall not be deemed a waiver of
              any subsequent right or remedy.
            </li>
            <li>
              A delay or failure to exercise, or the single or partial exercise of, any right or remedy shall not waive
              that or any other right or remedy, nor shall it prevent or restrict the further exercise of that or any
              other right or remedy.
            </li>
            <li>
              Each of the conditions of this Licence operates separately. If any court or competent authority decides
              that any of them are unlawful or unenforceable, the remaining conditions will remain in full force and
              effect.
            </li>
            <li>
              This Licence, its subject matter and its formation (and any non-contractual disputes or claims) are
              governed by European (Portuguese) law. We both irrevocably agree to the exclusive jurisdiction of the
              courts of Portugal.
            </li>
          </ol>
        </li>
      </ol>
    </div>
  </div>
</div>
<app-footer></app-footer>
