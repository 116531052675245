import { Component } from '@angular/core';

@Component({
  selector: 'policy-page',
  templateUrl: './policy-page.component.html',
  styleUrls: ['./policy-page.component.scss']
})
export class PolicyPageComponent {
  constructor() {}
}
