import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SupportModalComponent } from '../../components/modals/support-modal/support-modal.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  currentYear: number;

  constructor(private dialogService: MatDialog) {
    this.currentYear = new Date().getFullYear();
  }

  openSupportPopup() {
    this.dialogService.open(SupportModalComponent, {
      panelClass: 'dialog-overlay-pane'
    });
  }
}
