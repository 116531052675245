import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { DragScrollComponent, DragScrollItemDirective } from 'ngx-drag-scroll';
import { NgxMaterialIntlTelInputComponent } from 'ngx-material-intl-tel-input';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { TermsPageComponent } from './pages/terms-page/terms-page.component';
import { PolicyPageComponent } from './pages/policy-page/policy-page.component';

import { LoaderComponent } from './shared/components/loader/loader.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { SignupModalComponent } from './shared/components/modals/signup-modal/signup-modal.component';
import { SupportModalComponent } from './shared/components/modals/support-modal/support-modal.component';
import { SuccessModalComponent } from './shared/components/modals/success-modal/success-modal.component';

import { LoaderInterceptor } from './interceptors/loader.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    TermsPageComponent,
    PolicyPageComponent,
    HeaderComponent,
    FooterComponent,
    SignupModalComponent,
    SupportModalComponent,
    SuccessModalComponent,
    LoaderComponent
  ],
  bootstrap: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatExpansionModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    FormsModule,
    DragScrollComponent,
    DragScrollItemDirective,
    NgxMaterialIntlTelInputComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {}
