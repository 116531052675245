<div class="modal-header">
  <h1>Contact Support Center</h1>
  <span class="icon-Close close-icon" title="Close" (click)="closeModal()"></span>
</div>
<div class="modal-content">
  <form [formGroup]="form" class="form" *ngIf="!isFormSent; else successTemplate">
    <p class="message">
      Describe your question or suggestion with the necessary details. The response will be sent to the provided email
      address.
    </p>
    <div class="form__field-group">
      <input formControlName="email" class="form__field" type="text" placeholder="Email address" />

      <ng-container *ngIf="form.get('email')!.invalid && form.get('email')!.touched">
        <span *ngIf="form.get('email')!.hasError('required')" class="form__error"> Email should be present </span>
        <span *ngIf="form.get('email')!.hasError('email')" class="form__error"> Valid email address required </span>
      </ng-container>
    </div>

    <div class="form__field-group">
      <textarea formControlName="description" class="form__field" placeholder="Enter your question"></textarea>

      <ng-container *ngIf="form.get('description')!.invalid && form.get('description')!.touched">
        <span *ngIf="form.get('description')!.hasError('required')" class="form__error">
          Question should be present
        </span>
      </ng-container>
    </div>

    <button class="form-button" (click)="onSubmit()" [disabled]="form.invalid">Send</button>
  </form>

  <ng-template #successTemplate>
    <div class="success">
      <p>Your message has been successfully sent to the support center. We'll review it and get back to you shortly.</p>
      <p>Thank you for reaching out!</p>
    </div>
    <button class="form-button" (click)="closeModal()">Close</button>
  </ng-template>
</div>
